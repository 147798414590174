/* global autosize bsCustomFileInput LANGUAGE Sentry */


// #############################################################################
// GLOBAL VARS

const $window = $(window);
const $body = $('body');
const $modal_wrapper = $('#modal_wrapper');


// #############################################################################
// SENTRY

// function initSentry() {
//   let dsn = $body.data('sentry-dsn');
//
//   if (!dsn) {
//     return;
//   }
//
//   Sentry.init({
//     dsn: dsn,
//     environment: $body.data('sentry-env'),
//     release: $body.data('version'),
//   });
// }
//
// initSentry();


// #############################################################################
// AJAX SETUP

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

$.ajaxSetup({
  cache: false,
  beforeSend: function(xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader(
        'X-CSRFToken', $('[name=csrfmiddlewaretoken]').val()
      );
    }
  },
  error: function(e) {
    console.log('ERROR:', e, e.status, e.statusText);
  }
});


// #############################################################################
// HELPERS

function formatFileSize(bytes, decimal_point) {
  if (bytes == 0) {
    return '0 Bytes';
  }

  const k = 1000,

    dm = decimal_point || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


// #############################################################################
// FOCUS

function initFocus() {
  let focus_method = false;
  let last_focus_method = false;

  $body.on('focus', 'a, [tabindex]', function() {
    if (!focus_method) {
      focus_method = last_focus_method;
    }

    $('a, [tabindex]').attr('data-focus-method', focus_method);

    last_focus_method = focus_method;
    focus_method = false;
  });

  $body.on('blur', 'a, [tabindex]', function() {
    $('a, [tabindex]').removeAttr('data-focus-method');
  });

  $window.on('blur', function() {
    focus_method = false;
  });

  // Keyboard

  $body.on('keydown', 'a, [tabindex]', function() {
    focus_method = 'key';
  });

  // Mouse

  $body.on('mousedown', 'a, [tabindex]', function() {
    if (focus_method === 'touch') {
      return;
    }

    focus_method = 'mouse';
  });

  // Touch

  $body.on('touchstart', 'a, [tabindex]', function() {
    focus_method = 'touch';
  });
}

initFocus();


// #############################################################################
// TOAST

function updateToast($data) {
  if ($data['message']) {
    $.toast({
      hideAfter: 3000,
      icon: $data['submit'],
      stack: 2,
      text: $data['message'],
    });
  }
}


// #############################################################################
// TOOLTIP

function initToolTip() {
  $('[data-toggle="tooltip"]').tooltip();
}

initToolTip();


// #############################################################################
// FORM: FILE

bsCustomFileInput.init();


// #############################################################################
// FORM: VALIDATION

function initFormValidation() {

  // Insert error message

  function insertErrorMessage($input, $errors) {
    if (!$errors) {
      return;
    }

    let input_name = $input.attr('name');
    let $error = $('#id_' + input_name + '_error');

    if ($input.is(':checkbox') || $input.is(':radio')) {
      $error = $('#id_' + input_name + '_0_error');
    }

    if ($errors[input_name]) {
      $input.addClass('is-invalid');
      $error.text($errors[input_name].join(' '));
    }
  }

  // Validate input

  function checkInputMaxSize($input) {
    let sizes = 0;

    const $files = $input[0].files;
    const max_size = $input.data('max-size');

    if ($files.length === 0) {
      return true;
    }

    $.each($files, function(index) {
      sizes += $files[index].size;
    });

    if (sizes > max_size) {
      const input_name = $input.attr('name');
      let $errors = {};

      $errors[input_name] = [
        LANGUAGE['max_file_size'].replace(
          '%max_upload_size', formatFileSize(max_size)
        ).replace(
          '%size', formatFileSize(sizes)
        )
      ];

      insertErrorMessage($input, $errors);

      return false;
    }

    return true;
  }

  function checkInputValidity($input) {
    let max_size_is_valid = true;

    if ($input.is(':file')) {
      max_size_is_valid = checkInputMaxSize($input);
    }

    if ($input[0].checkValidity() && max_size_is_valid) {
      let input_name = $input.attr('name');
      let $error = $('#id_' + input_name + '_error');

      if ($input.is(':radio')) {
        $input = $('[name="' + input_name + '"]');
        $error = $('#id_' + input_name + '_0_error');
      }

      $input.removeClass('is-invalid');
      $error.empty();

      return true;
    }

    return false;
  }

  // Validate before submit

  $body.on('blur input', '[data-form] :input:visible:not(button)', function() {
    let $input = $(this);
    let is_valid = checkInputValidity($input);

    if (!is_valid) {
      let $form = $input.parents('[data-form]');

      $.ajax({
        contentType: false,
        data: new FormData($form[0]),
        processData: false,
        type: 'POST',
        url: $form.attr('action'),
        success: function($data) {
          insertErrorMessage($input, $data['errors']);
        },
      });
    }
  });

  // Validate after submit

  $body.on('submit', '[data-form]', function(event) {
    event.preventDefault();

    let $form = $(this);
    let $inputs = $(':input:visible:not(button)', $form);

    if (!$form[0].checkValidity()) {
      $form.find('[required]:invalid').first().focus();

      $inputs.each(function(index) {
        checkInputValidity($inputs.eq(index));
      });
    }

    let $form_data = new FormData($form[0]);

    $form_data.append('submit', 1);

    $.ajax({
      contentType: false,
      data: $form_data,
      processData: false,
      type: 'POST',
      url: $form.attr('action'),
      success: function($data) {
        if ($data['submit'] === 'error') {
          $inputs.each(function(index) {
            insertErrorMessage($inputs.eq(index), $data['errors']);
          });
        }
      },
    });
  });
}

initFormValidation();


// #############################################################################
// FORM: AJAX UPLOAD

function initAjaxUpload() {
  let $files;
  let progress_item;

  function changeButtonStatus($wrapper, $input) {
    const $button = $('button', $wrapper);

    if ($files.length > 0 && !$input.hasClass('is-invalid')) {
      $button.removeClass('disabled');
    }
    else {
      $button.addClass('disabled');
    }
  }

  function initProgressBar($wrapper, $input) {
    const $progress_items = $('[data-progress-items]', $wrapper);

    if (!progress_item) {
      const $progress_item = $('[data-progress-item]', $progress_items);
      progress_item = $progress_item.parent().html();
    }

    $progress_items.empty();

    if ($files.length > 0 && !$input.hasClass('is-invalid')) {
      $progress_items.removeAttr('hidden');

      $.each($files, function(index) {
        let file_size = $files[index].size;

        let $item = $(progress_item.replace(
          '%filename', $files[index].name
        ).replace(
          '%filesize', formatFileSize(file_size, 2)
        ).replace(
          'data-progress-item', 'data-progress-item="' + $files[index].name + '"'
        ));

        $progress_items.append($item);
      });
    }
    else {
      $progress_items.attr('hidden', 'hidden');
    }
  }

  function updateProgressBar($file) {
    const $xhr = $.ajaxSettings.xhr();
    const $item = $('[data-progress-item="' + $file.name + '"]');
    const $progress_bar = $('[data-progress-bar]', $item);
    const $current_file_size = $('[data-current-file-size]', $item);

    if ($xhr.upload) {
      $xhr.upload.addEventListener('progress', function(event) {
        if(event.lengthComputable) {
          let max = event.total;
          let current = event.loaded;
          let percentage = (current * 100) / max;

          $progress_bar.css('width', percentage + '%');
          $progress_bar.attr('aria-valuenow', percentage);
          $current_file_size.html(formatFileSize(current));
        }
      }, false);
    }

    return $xhr;
  }

  $body.on('change', '[data-ajax-upload] input[type="file"]', function() {
    const $input = $(this);
    const $wrapper = $input.parents('[data-ajax-upload]');

    $files = this.files;

    initProgressBar($wrapper, $input);
    changeButtonStatus($wrapper, $input);
  });

  $body.on('click', '[data-ajax-upload] button', function() {
    const $button = $(this);
    const $wrapper = $button.parents('[data-ajax-upload]');
    const $input = $('input[type="file"]', $wrapper);

    if ($files.length > 0) {
      $button.addClass('disabled');
      $input.attr('disabled', 'disabled');

      $.each($files, function(index) {
        const $form_data = new FormData();
        const $file = $files[index];

        $form_data.append('file', $file);

        $.ajax({
          contentType: false,
          data: $form_data,
          processData: false,
          type: 'POST',
          url: $wrapper.data('ajax-upload'),
          success: function() {
            $input.removeAttr('disabled');
          },
          xhr: function() {
            return updateProgressBar($file);
          }
        });
      });
    }

    return false;
  });
}

initAjaxUpload();


// #############################################################################
// FORM: TEXTAREA

autosize($('textarea'));


// #############################################################################
// MODAL

function hideModal() {
  if ($modal_wrapper.length !== 0) {
    $('[data-modal]', $modal_wrapper).modal('hide');
  }
}

function initModal() {
  if ($modal_wrapper.length === 0) {
    return;
  }

  $body.on('click', '[data-modal-link]', function() {
    $('[data-modal]', $modal_wrapper).modal('dispose');

    $.ajax({
      url: this.href,
      success: function($data) {
        if ($.type($data) === 'string') {
          $modal_wrapper.empty().html($data);

          const $modal = $('[data-modal]', $modal_wrapper);

          $modal.modal();

          $modal.on('shown.bs.modal', function() {
            $('[autofocus]', $modal_wrapper).focus();
          });

          $modal.on('hidden.bs.modal', function() {
            $modal_wrapper.empty();
          });

          autosize($('[data-modal] textarea'));
        }
        else {
          reloadDatatable($data);
          updateToast($data);
        }
      },
    });

    return false;
  });
}

initModal();


// #############################################################################
// DATA TABLE

function reloadDatatable($data) {
  if ($data['datatable']) {
    let top = $window.scrollTop();

    window.datatable.ajax.reload(function() {
      $window.scrollTop(top);
    }, false);
  }
}

function initDataTable(table, $options, $buttons = []) {
  const $table = $('[data-' + table + ']');
  const $inputs = $('[data-input]', $table);
  const $selects = $('[data-select]', $table);

  if ($table.length === 0) {
    return;
  }

  // Custom classes

  $.fn.dataTable.ext.classes.sInfo = 'dataTables_info ml-2';
  $.fn.dataTable.ext.classes.sLength = 'dataTables_length';
  $.fn.dataTable.ext.classes.sLengthSelect = 'custom-select';
  $.fn.dataTable.ext.classes.sPageButton = 'page-item page-link';
  $.fn.dataTable.ext.classes.sPageButtonActive = 'active';
  $.fn.dataTable.ext.classes.sProcessing = 'dataTables_processing text-center p-2 position-absolute';
  $.fn.dataTable.ext.classes.sSortAsc = 'sorting-asc';
  $.fn.dataTable.ext.classes.sSortColumn = 'sorting-';
  $.fn.dataTable.ext.classes.sSortDesc = 'sorting-desc';
  $.fn.dataTable.ext.classes.sTable = 'datatable-table';

  let $default_buttons = [
    {
      className: 'btn btn-secondary',
      text: LANGUAGE['resetButton'],
      action: function(e, $dt) {
        $dt.columns().search('').draw();

        $inputs.val('');

        $selects.each(function(index) {
          let $select = $selects.eq(index);
          let value = $('[selected]', $select).val();

          if (value) {
            $select.val(value);
          } else {
            $select.val('');
          }
        });
      }
    },
    {
      className: 'btn btn-secondary',
      extend: 'csv',
      exportOptions: {
        columns: ['thead th:not(.no-export)', ':visible'],
      },
      text: LANGUAGE['exportCSV'],
      init: function($dt, $node) {
        let $export_csv = $('[data-export-csv]', $body).length;

        if ($export_csv === 0) {
          $node.addClass('d-none');
        }
      },
      customize: function(csv) {
        // For customization read https://datatables.net/reference/button/csv
        return csv
      }
    },
  ];

  $.merge($default_buttons, $buttons);

  function initFilters(table) {
    let $api = table.api();
    let $state = $api.state.loaded();

    // Restore saved filter values

    if ($state) {
      $api.columns().eq(0).each(function(index) {
        let $search = $state.columns[index].search;

        if ($search) {
          let search = $search.search;

          if (search) {
            $('[data-column="' + index + '"]').val(search);
          }
        }
      });
    }

    // Filter

    let timeout;

    $inputs.on('input', function() {
      let $input = $inputs.eq($inputs.index(this));
      let $column = $api.column($input.data('column'));
      let value = this.value;

      clearTimeout(timeout);

      timeout = setTimeout(function() {
        $column.search(value).draw();
      }, 200);
    });

    $selects.on('change', function() {
      let $select = $selects.eq($selects.index(this));
      let column = $select.data('column');
      let $column = $api.column(column);

      $column.search($select.val()).draw();
    });
  }

  let $defaults = {
    autoWidth: false,
    ajax: {
      url: $table.data(table),
    },
    buttons: {
      buttons: $default_buttons,
    },
    columnDefs: [
      {
        'sortable': false,
        'targets': 'no-sort',
      }
    ],
    dom: '<"row"<"col-auto"li><"col"B><"col-auto ml-auto"p><"col-12"rt><"col"li><"col-auto ml-auto"p>',
    language: {
      sEmptyTable: LANGUAGE['sEmptyTable'],
      sZeroRecords: LANGUAGE['sZeroRecords'],
      sInfo: LANGUAGE['sInfo'],
      sInfoEmpty: LANGUAGE['sInfoEmpty'],
      sInfoThousands: '.',
      sLengthMenu: LANGUAGE['sLengthMenu'],
      sProcessing: LANGUAGE['sProcessing'],
      paginate: {
        previous: LANGUAGE['paginate']['previous'],
        next: LANGUAGE['paginate']['next'],
      },
      aria: {
        sortDescending: LANGUAGE['oAria']['sortDescending'],
        sortAscending: LANGUAGE['oAria']['sortAscending'],
      },
    },
    lengthMenu: [
      [10, 50, 100, -1],
      [10, 50, 100, LANGUAGE['all']],
    ],
    ordering: true,
    pagingType: 'numbers',
    processing: true,
    responsive: false,
    serverSide: true,
    stateSave: true,
    initComplete: function() {
      initFilters(this);
    }
  };

  $.extend($defaults, $options);

  window.datatable = $table.DataTable($defaults);

  return window.datatable
}


// #############################################################################
// DATA TABLE: DEMO

function initDataTableDemo() {
  if (!$.fn.dataTable) {
    return;
  }

  const $options = {
    columns: [
      {
        data: 'username',
      },
    ],
  };

  const $table = initDataTable('demo-table', $options);

  if ($table) {
    $table.on('init', function() {
      // Custom code on init
    });

    $table.on('draw', function() {
      // Custom code on redraw
    });
  }
}

initDataTableDemo();
